
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "ModalStatic",
  props: {
    input: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
});
